:root {
  --black: #000;
  --white: #fff;
  --orange: #ff5900;
  --lOrange: #ff975f;
  --gray: rgba(47, 47, 47, 0.5);
  --lightGray: #f5f5f5;
  --gray2: #d9d9d9;
  --error: #eb4d4b;

  --ion-background-color: #fff;

  --containerWidthBigMobile: 450px;
  --containerWidthBigPadding: 20px;

  --containerWidthMobile: 375px;
  --containerWidthPadding: 15px;

  --sport-color: #b6dce3;
  --fit-color: rgba(255, 221, 171, 1);
  --life-color: rgba(207, 194, 239, 1);
  --veg-color: rgba(215, 228, 206, 1);

  --sport-color-new: #e5f3f6;
  --fit-color-new: #ffeacc;
  --life-color-new: rgb(219, 210, 242);
  --veg-color-new: #e7eee1;

  --sport-color-active: #b6dce3;
  --fit-color-active: rgba(255, 221, 171, 1);
  --life-color-active: rgba(207, 194, 239, 1);
  --veg-color-active: rgba(215, 228, 206, 1);

  --main-blue: #232453;
  --green-success: #28a745;
}
