@import 'core/utils';

.container,
.header {
  max-width: var(--containerWidthBigMobile);
  padding-right: var(--containerWidthBigPadding);
  padding-left: var(--containerWidthBigPadding);
  width: 100%;
  margin: 0 auto;
}

@include small-mobile {
  .container,
  .header {
    max-width: var(--containerWidthMobile);
    padding-right: var(--containerWidthPadding);
    padding-left: var(--containerWidthPadding);
    width: 100%;
    margin: 0 auto;
  }
}

ion-toolbar {
  --background: transparent;
  --border-width: 0 !important;
}
