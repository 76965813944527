@import 'core/utils';

.select-label {
  position: relative;
  font-size: 10px;
  color: #00000066;
  padding: 0;
  margin: 0 0 -20px;
  z-index: 1002;
}

.ng-select.ng-select-single {
  .ng-select-container {
    height: 50px;
    border: 0;
    border-bottom: 1px solid var(--gray2);
    border-radius: 0;
    padding: 16px 0 0;
    font-size: 14px;
    letter-spacing: 0.8px;
    color: #000;

    .ng-value-container {
      padding-left: 0;
    }

    span.ng-arrow-wrapper {
      padding-right: 5px;
      width: auto;

      span.ng-arrow {
        top: 0 !important;
        display: inline-block;
        width: 9px;
        height: 14px;
        border: 0 none !important;
        transition: all 0.5s ease;
        content: url('/assets/icons/arrow-right.svg');
      }
    }
  }

  &.ng-select-opened > .ng-select-container .ng-arrow {
    transform: rotate(90deg);
  }

  &.ng-select-focused > .ng-select-container {
    border: 0;
    border-bottom: 1px solid var(--gray2);
    box-shadow: none;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  display: flex !important;
  align-items: center !important;
  height: 50px !important;

  &.ng-option-marked,
  &.ng-option-selected {
    background-color: var(--lightGray) !important;
  }
}
