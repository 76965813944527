
.error-snackbar {
  .toast-container {
    background: var(--orange);
    color: var(--white);
  }
}

.success-snackbar {
  .toast-container {
    background: #94D097;
    color: var(--black);
  }
}
