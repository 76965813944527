

@font-face {
  font-family: 'SFPro';
  font-weight: 700;
  font-style: normal;
  src:
    url('/assets/fonts/sf/SFProDisplay-Bold.woff2') format('woff2'),
    url('/assets/fonts/sf/SFProDisplay-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SFPro';
  font-weight: 600;
  font-style: normal;
  src:
    url('/assets/fonts/sf/SFProDisplay-Semibold.woff2') format('woff2'),
    url('/assets/fonts/sf/SFProDisplay-Semibold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SFPro';
  font-weight: 500;
  font-style: normal;
  src:
    url('/assets/fonts/sf/SFProDisplay-Medium.woff2') format('woff2'),
    url('/assets/fonts/sf/SFProDisplay-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SFPro';
  font-weight: 400;
  font-style: normal;
  src:
    url('/assets/fonts/sf/SFProDisplay-Regular.woff2') format('woff2'),
    url('/assets/fonts/sf/SFProDisplay-Regular.woff') format('woff');
  font-display: swap;
}

