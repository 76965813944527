@use 'sass:map';

// media queries
@mixin small-mobile {
  @media screen and (max-width: 375px) {
    @content;
  }
}

@mixin medium-mobile {
  @media screen and (max-width: 414px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin mobile-tablet {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin tablet-desktop {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1025px) {
    @content;
  }
}

@mixin landscape {
  @media all and (orientation: landscape) {
    @content;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.p-rel {
  position: relative;
}

.ovh {
  overflow: hidden;
}

.vhidden {
  visibility: hidden;
}

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);
$sides: (top, bottom, left, right);

@each $space-index,
  $space-value
    in map.merge(
      $spacers,
      (
        'auto': 'auto',
      )
    )
{
  .m {
    &-#{$space-index} {
      margin: #{$space-value} !important;
    }

    &y-#{$space-index} {
      margin-top: #{$space-value} !important;
      margin-bottom: #{$space-value} !important;
    }
    &x-#{$space-index} {
      margin-left: #{$space-value} !important;
      margin-right: #{$space-value} !important;
    }

    @each $side in $sides {
      &#{str-slice($side, 0, 1)}-#{$space-index} {
        margin-#{$side}: #{$space-value} !important;
      }
    }
  }
}

@each $space-index, $space-value in $spacers {
  .p {
    &-#{$space-index} {
      padding: #{$space-value} !important;
    }

    &y-#{$space-index} {
      padding-top: #{$space-value} !important;
      padding-bottom: #{$space-value} !important;
    }
    &x-#{$space-index} {
      padding-left: #{$space-value} !important;
      padding-right: #{$space-value} !important;
    }

    @each $side in $sides {
      &#{str-slice($side, 0, 1)}-#{$space-index} {
        padding-#{$side}: #{$space-value} !important;
      }
    }
  }

  .gap {
    &-#{$space-index} {
      gap: #{$space-value} !important;
    }
  }
}
