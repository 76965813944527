@import 'components/buttons';
@import 'core/utils';

.picker-custom {
  --max-width: 100%;
  --border-radius: 20px 20px 0px 0px;
  --height: 340px;

  .picker-cancel-btn {
    position: absolute;
    left: 0;
    top: 15px;
    color: transparent;
    text-indent: -1000px;
    overflow: hidden;
    width: 36px;
    height: 36px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 9px;
      height: 15px;
      background: url(/assets/images/modal-back.svg) no-repeat;
    }
  }

  .picker-title {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    pointer-events: none;
    cursor: default;
    color: var(--ion-text-color);
    white-space: nowrap;
    top: 11px;
    letter-spacing: 0.8px;
  }

  .picker-save-btn {
    max-width: var(--containerWidthBigMobile);
    width: 100%;
    margin: 0 auto;
  }

  .picker-toolbar {
    position: absolute;
    height: -webkit-fill-available;
  }

  .picker-columns {
    z-index: 1;
    margin-top: 50px;
  }
}
