ion-select {
  border-bottom: 1px solid #d9d9d9;
}

ion-select::part(label) {
  font-size: 15px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.4);
}

ion-select::part(icon) {
  width: 20px;
  padding-top: 8px;
  margin-right: -4px;
}
