.input {
  display: block;
  position: relative;

  &__wrapper {
    position: relative;
    background: var(--white);

    .icon-loading {
      position: absolute;
      right: 0;
      bottom: 1px;
      top: 0;
    }
  }

  &__area {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;

    border-radius: 12px;
    padding: 0 25px 0 10px;
    font-size: 18px;
    line-height: 48px;
    color: var(--black);
    border: 1px solid rgba(0, 0, 0, 0);
    background: #f6f6f6;
    width: 100%;
    margin: 0;
    box-shadow: none;
    transition: border-color 0.3s ease;
    outline: none !important;
    letter-spacing: 0.8px;

    &:focus {
      outline: none !important;
      border-color: transparent;
    }
  }

  &__placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    padding-left: 12px;
    padding-bottom: 1px;
    font-size: 14px;
    color: rgba(#000, 0.4);
    transition: transform 0.2s ease;
    pointer-events: none;

    .input--has-value & {
      opacity: 0;

      &.floating {
        opacity: 1;
        transform: translateY(-21px);
        font-size: 10px;
        line-height: 1;
      }
    }
  }

  .done {
    position: absolute;
    top: 0;
    right: 15px;
    max-width: 15px;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    color: #ff5900;
  }

  &--done .done {
    opacity: 1;
  }

  .error,
  .custom-message {
    position: absolute;
    left: 0;
    top: 100%;
    display: none;
    margin-top: 10px;
    font-size: 14px;
    letter-spacing: 0.8px;
  }

  .error {
    color: var(--error);
    opacity: 0;
    transition: all 0.3s ease;
  }

  .custom-message {
    color: var(--black);
  }

  &--error {
    .error {
      display: block;
      opacity: 1;
    }

    .input__area,
    .input__area.input--error {
      border-color: var(--error);
    }
  }

  &--custom-message .custom-message {
    display: block;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.input-theme-flat,
.input-theme-flat-big {
  letter-spacing: 0.8px;

  .input {
    &__area {
      border-radius: 0;
      background: none;
      border-color: transparent transparent #d9d9d9 transparent;
      font-size: 14px;
      padding-left: 0;
      padding-top: 8px;
    }

    &__placeholder {
      padding-left: 0;
    }
  }

  .done {
    right: 5px;
  }

  .error,
  .custom-message {
    font-size: 12px;
    line-height: 1;
    margin-top: 5px;
    text-align: left;
  }

  .custom-message {
    color: var(--gray);
  }
}

.input-theme-flat {
  .input__area {
    line-height: 29px;
    height: 41px;
  }
}

.input-theme-flat-big {
  .input__area {
    line-height: 39px;
    height: 41px;
  }
}

.input-theme-search {
  display: flex;
  flex-direction: column;
  letter-spacing: 0.8px;

  .input__area {
    border-radius: 12px;
    background: #fff;
    border: 1px solid rgba(32, 31, 29, 0.3);
    font-size: 14px;
    font-weight: 500;
    line-height: 42px;
  }

  .suggestions-container {
    margin-top: 15px;
    height: 50vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .error,
  .custom-message {
    margin-top: 3px;
    font-size: 12px;
  }
}
