
.dot-flashing {
  $dot-size: 10px; // пиксели для того, чтобы сохранить круглую форму точки

  margin: auto;
  position: relative;
  width: $dot-size;
  height: $dot-size;
  border-radius: 50%;
  animation: dotFlashingBlack .5s infinite linear alternate;
  animation-delay: .25s;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    display: inline-block;
    width: $dot-size;
    height: $dot-size;
    border-radius: 50%;
    animation: dotFlashingBlack .5s infinite linear alternate;
  }

  &::before {
    left: -1.5rem;
    animation-delay: 0s;
  }

  &::after {
    left: 1.5rem;
    animation-delay: .5s;
  }

  &--absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &--black {
    animation-name: dotFlashingBlack;
    background-color: var(--black);
    color: var(--black);

    &::before,
    &::after {
      background-color: var(--black);
      color: var(--black);
      animation-name: dotFlashingBlack;
    }
  }

  &--white {
    animation-name: dotFlashingWhite;
    background-color: var(--white);
    color: var(--white);

    &::before,
    &::after {
      background-color: var(--white);
      color: var(--white);
      animation-name: dotFlashingWhite;
    }
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes dotFlashingBlack {
  0% {
    background-color: var(--black);
  }
  50%,
  100% {
    background-color: transparent;
  }
}

@keyframes dotFlashingWhite {
  0% {
    background-color: var(--white);
  }
  50%,
  100% {
    background-color: transparent;
  }
}
