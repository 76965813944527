html {
  font-size: 10px;
  min-width: 320px;

  @include mobile {
    font-size: calc(10px + 13 * (100vw - 320px) / 447);
  }

  @include tablet {
    font-size: calc(10px + 4 * (100vw - 768px) / 256);
  }

  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    font-size: calc(5.6px + 4.4 * (100vw - 768px) / 512);
  }

  @media screen and (min-width: 1281px) {
    font-size: 10px;
  }
}

body {
  color: var(--ion-text-color);
  font-family: var(--ion-font-family);
  font-size: var(--ion-text-size);
  background-color: var(--ion-background-color);
}

::selection {
  background: #000;
  color: #fff;
}

.ion-page {
  max-width: var(--containerWidthBigMobile);
  margin: 0 auto;
  // padding-bottom: var(--ion-safe-area-bottom);
}

iframe.with-appled {
  padding-top: 59px;
  background-color: #fff;
}
